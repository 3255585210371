import React from 'react'
import './index.scss'
import DownloadAttachmentImage from '../../../images/attachment.png'
import DownloadEmailImage from '../../../images/save-email.png'

const DownloadAttachmentsSection = () => {
  return (
    <section 
      id="download-attachments" 
      className="download-attachments-container"
      data-scroll 
      data-scroll-id="download-attachments"
      >
      <p
        className="download-attachments-title"
        >
        Download <span className="email">email</span> source <br/> and <span className="attachments">attachments</span>.
      </p>
      <div>
        <img 
          className="download-attachments-attachment-image"
          src={DownloadAttachmentImage}
          data-scroll
          data-scroll-direction="vertical"
          data-scroll-speed="-2"
        />
        <img 
          className="download-attachments-email-image"
          src={DownloadEmailImage}
          data-scroll
          data-scroll-direction="vertical"
          data-scroll-speed="2"
        />
      </div>
    </section>
  )
}

export default DownloadAttachmentsSection;