import React, { useEffect, useState } from 'react';
import './index.scss';
import { AnimatePresence, motion } from 'framer-motion';
import fullscreenApp from '../../../images/fullscreen.png'
import logo from '../../../images/tempbox_logo.png'

const features = ["Test your services", "Prevent Spam", "Download resources", "Protect your privacy"];

const Hero = () => {

  const [featureIndex, setFeatureIndex] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setFeatureIndex((index) => (index + 1) % features.length);
    }, 2200);
  }, []);

  return (
    <section 
      className="hero-section" 
      id="hero" 
      data-scroll 
      data-scroll-id="hero"
    >
      <div className="logo-container">
        <img src={logo}/>
        <p>Tempbox</p>
      </div>
      <div
        data-scroll
        data-scroll-speed="-3"
      >
        <p className="hero-title">Create instant disposable emails to</p>
        <AnimatePresence>
          <motion.p 
              key={features[featureIndex]}
              className="hero-subtitle"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              // exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {features[featureIndex]}
            </motion.p>
        </AnimatePresence>
      </div>
      <img 
        className="hero-app-image"
        src={fullscreenApp}
        data-scroll
        data-scroll-speed="6"
      />
    </section>
  )
}

export default Hero;