import React, { useEffect, useState, useRef } from "react"
import SEO from '../components/seo'
import '../styles/index.scss';
import { motion } from 'framer-motion';
import Hero from '../components/home/hero/hero';
import Footer from '../components/home/footer';
import DisposableEmailsSection from "../components/home/disposable emails";
import AddressSection from "../components/home/address";
import DownloadAttachmentsSection from "../components/home/download attachments";
import TakeOffSection from "../components/home/takeoff";


const backgroundStyles = {
  hero: {
    backgroundColor: "#FFEEF0", // pink
    footer: {
      background: "linear-gradient(0deg, rgba(255, 238, 240, 1) 0%, rgba(255, 238, 240, 0) 50%)",
      textColor: "#30343E",
      buttonBackground: "#BA1F33",
      buttonTextColor: "#FFEEF0",
    }
  }, 
  "disposableEmail": {
    backgroundColor: "#3D405B", // royalblue,
    footer: {
      background: "linear-gradient(0deg, rgba(61, 64, 91, 1) 0%, rgba(61, 64, 91, 0) 50%)",
      textColor: "#ffffff",
      buttonBackground: "#E07A5F",
      buttonTextColor: "#FFEEF0",
    }
  },
  "addressSection": {
    backgroundColor: "#F2CC8F", // yellow
    footer: {
      background: "linear-gradient(0deg, rgba(242, 204, 143, 1) 0%, rgba(242, 204, 143, 0) 50%)",
      textColor: "#3D405B",
      buttonBackground: "#3D405B",
      buttonTextColor: "#FFEEF0",
    }
  },
  "downloadAttachmentSection": {
    backgroundColor: "#E07A5F", // orange
    footer: {
      background: "linear-gradient(0deg, rgba(224, 122, 95, 1) 0%, rgba(224, 122, 95, 0) 50%)",
      textColor: "#ffffff",
      buttonBackground: "#F2CC8F",
      buttonTextColor: "#30343E",
    }
  },
  "takeoffSection": {
    backgroundColor: "#30343E",
    footer: {
      background: "linear-gradient(0deg, rgba(48, 52, 62, 1) 0%, rgba(48, 52, 62, 0) 50%)",
      textColor: "#ffffff",
      buttonBackground: "#81B29A",
      buttonTextColor: "#FFEEF0",
    }
  }
}

const downloadUrl="https://github.com/devwaseem/TempBox/releases/download/v1.1/TempBox.dmg"
const latestVersion="v1.1"

var scroll;
const IndexPage = () => {
  const containerRef = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [backgroundStyle, setBackgroundStyle] = useState(backgroundStyles.hero)

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    import('locomotive-scroll').then((locomotiveModule)=> {
      scroll = new locomotiveModule.default({
        el: containerRef.current,
        smooth: true,
        smartphone: {
          smooth: true,
        },
        tablet: {
          smooth: true,
        }
      })

      scroll.on('scroll', (args) => {
        // const { limit, scroll } = args;
        // setScrollData({
        //   offset: scroll.y,
        //   limit: limit.y,
        // })

          // let percentage = (scroll.y / limit.y) * 100
          // setShowSegmentedBar(percentage > 4 && percentage < 96);
          checkSectionAndSetConfig(args);
          // if(scroll) {
            // scroll?.update()
          // }
        });

    })
    

  return () => {
    scroll?.destroy()
  }

  }, []);

  function checkSectionAndSetConfig(args) {
    function isCurrentSection(sectionId) {
      return typeof args.currentElements[sectionId] === 'object'
    }
    if (isCurrentSection("takeoff-section")) {
      setBackgroundStyle(backgroundStyles.takeoffSection);
    } else if (isCurrentSection("download-attachments")){
      setBackgroundStyle(backgroundStyles.downloadAttachmentSection);
    } else if (isCurrentSection("address-section")) {
      setBackgroundStyle(backgroundStyles.addressSection);
    } else if (isCurrentSection("disposable-email")) {
      setBackgroundStyle(backgroundStyles.disposableEmail);
    } else {
      setBackgroundStyle(backgroundStyles.hero);
    }
    
    // }else if (isCurrentSection(getStartedId)) {
    //   setCurrentSection(getStartedId);
    // }
    
  }

  if (!hasMounted) {
    return null;
  }


  return (
    <>
    <SEO/>
      <Footer 
        backgroundStyle={backgroundStyle} 
        downloadUrl={downloadUrl}
      />
      <motion.main
        ref={containerRef}
        data-scroll-container
        data-scroll
        animate={{
          backgroundColor: backgroundStyle.backgroundColor,
        }}
        transition={{ duration: 1 }}
      >
        <Hero />
        <DisposableEmailsSection />
        <AddressSection />
        <DownloadAttachmentsSection />
        <TakeOffSection 
          downloadUrl={downloadUrl} 
          latestVersion={latestVersion}
        />
      </motion.main>
      
    </>
  )
}

export default IndexPage
