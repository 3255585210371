import React from 'react'
import './index.scss'
import DisposableEmailsImage from '../../../images/disposable email.png'

const DisposableEmailsSection = () => {
  return (
    <section 
      id="disposable-email" 
      className="disposable-email-container"
      data-scroll 
      data-scroll-id="disposable-email"
      >
      <p
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-speed="-10" 
        className="disposable-email-title"
        >
        Create multiple <span className="disposable">disposable</span> emails. <br/>
      </p>
      <p
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-speed="10"
        className="disposable-email-title">
          <span className="archive">Archive</span> emails for later use.
        </p>
      <img 
        className="disposable-email-image"
        src={DisposableEmailsImage}
        data-scroll
        data-scroll-speed="20"
      />
    </section>
  )
}

export default DisposableEmailsSection;