import React from 'react';
import './index.scss';
import { motion } from 'framer-motion';
import NewAddressImage from '../../../images/NewAddressImage.png'

const AddressSection = () =>  {
  return (
  <motion.section 
      id="address-section" 
      className="address-section-container"
      data-scroll
      data-scroll-id="address-section"
      >
      <span
        data-scroll
        className="address-section-title">
        <span data-scroll data-scroll-delay="0.04" data-scroll-speed="3">M</span>
        <span data-scroll data-scroll-delay="0.05" data-scroll-speed="3">a</span>
        <span data-scroll data-scroll-delay="0.06" data-scroll-speed="3">k</span>
        <span data-scroll data-scroll-delay="0.07" data-scroll-speed="3">e</span>
        {" "}
        <span data-scroll data-scroll-delay="0.09" data-scroll-speed="3">t</span>
        <span data-scroll data-scroll-delay="0.10" data-scroll-speed="3">h</span>
        <span data-scroll data-scroll-delay="0.11" data-scroll-speed="3">e</span>
        {" "}
        <span data-scroll data-scroll-delay="0.13" data-scroll-speed="3">d</span>
        <span data-scroll data-scroll-delay="0.14" data-scroll-speed="3">i</span>
        <span data-scroll data-scroll-delay="0.15" data-scroll-speed="3">s</span>
        <span data-scroll data-scroll-delay="0.16" data-scroll-speed="3">p</span>
        <span data-scroll data-scroll-delay="0.17" data-scroll-speed="3">o</span>
        <span data-scroll data-scroll-delay="0.18" data-scroll-speed="3">s</span>
        <span data-scroll data-scroll-delay="0.19" data-scroll-speed="3">a</span>
        <span data-scroll data-scroll-delay="0.20" data-scroll-speed="3">b</span>
        <span data-scroll data-scroll-delay="0.21" data-scroll-speed="3">l</span>
        <span data-scroll data-scroll-delay="0.21" data-scroll-speed="3">e</span>
        {" "}
        <span data-scroll data-scroll-delay="0.21" data-scroll-speed="3">e</span>
        <span data-scroll data-scroll-delay="0.22" data-scroll-speed="3">m</span>
        <span data-scroll data-scroll-delay="0.21" data-scroll-speed="3">a</span>
        <span data-scroll data-scroll-delay="0.21" data-scroll-speed="3">i</span>
        <span data-scroll data-scroll-delay="0.21" data-scroll-speed="3">l</span>
        {" "}
        <span data-scroll data-scroll-delay="0.21" data-scroll-speed="3">a</span>
        <span data-scroll data-scroll-delay="0.21" data-scroll-speed="3">d</span>
        <span data-scroll data-scroll-delay="0.20" data-scroll-speed="3">d</span>
        <span data-scroll data-scroll-delay="0.19" data-scroll-speed="3">r</span>
        <span data-scroll data-scroll-delay="0.17" data-scroll-speed="3">e</span>
        <span data-scroll data-scroll-delay="0.16" data-scroll-speed="3">s</span>
        <span data-scroll data-scroll-delay="0.15" data-scroll-speed="3">s</span>
        {" "}
        <span data-scroll data-scroll-delay="0.13" data-scroll-speed="3">s</span>
        <span data-scroll data-scroll-delay="0.12" data-scroll-speed="3">u</span>
        <span data-scroll data-scroll-delay="0.11" data-scroll-speed="3">i</span>
        <span data-scroll data-scroll-delay="0.10" data-scroll-speed="3">t</span>
        {" "}
        <span data-scroll data-scroll-delay="0.08" data-scroll-speed="3">y</span>
        <span data-scroll data-scroll-delay="0.07" data-scroll-speed="3">o</span>
        <span data-scroll data-scroll-delay="0.06" data-scroll-speed="3">u</span>
        <span data-scroll data-scroll-delay="0.05" data-scroll-speed="3">.</span>
      </span>
      <p 
        className="address-section-subtitle">
        You can generate a random address or create one of your choice.
      </p>
      <img 
        className="address-section-image"
        src={NewAddressImage}
        data-scroll
        data-scroll-speed="10"
      />
    </motion.section>
  )
}

export default AddressSection;