import React from 'react';
import openInNewTab from '../../../utils/openInNewTab';
import './index.scss';


const TakeOffSection = ({ downloadUrl, latestVersion }) => {
  return (
    <section 
      id="takeoff-section" 
      className="takeoff-section-container"
      data-scroll 
      data-scroll-id="takeoff-section"
      >
      <p
        className="takeoff-section-title"
        >
        Ready to <span className="take-off">take off</span> ?
      </p>
      <p className="takeoff-section-subtitle">
        Tempbox is completely open sourced ⚡️.
      </p>
      <div className="take-off-button-container">
        <div 
          className="take-off-button mac"
          onClick={() => openInNewTab(downloadUrl)}
        >
          Download for Mac
        </div>
        or
        <div 
          className="take-off-button github"
          onClick={() => openInNewTab("https://github.com/devwaseem/TempBox")}
        >
          View on Github
        </div>
      </div>
      <p className="latest-version">Latest version: {latestVersion}</p>
      <div className="mobile-links-container">
        <p
          onClick={() => openInNewTab("https://twitter.com/TempboxMac")}
        >
          Twitter
        </p>
        <p
        onClick={() => openInNewTab("https://waseem.works")}
        >
          waseem.works
        </p>
        
      </div>
    </section>
  )
}

export default TakeOffSection;