import React from 'react';
import openInNewTab from '../../../utils/openInNewTab';
import './index.scss';


const Footer = ({ backgroundStyle, downloadUrl }) => {

  const { background, textColor, buttonBackground, buttonTextColor } = backgroundStyle.footer;

  return (
  <footer
    className="footer-container"
    style = {{
      backgroundImage: background
    }}
  >
    <div className="footer-content-container">
      <p 
        className="powered-by-mailtm"
        style={{ color: textColor }}
        onClick={() => openInNewTab("https://mail.tm")}
      >
        Powered by Mail.tm
      </p>
      <div className="footer-content-right">
        <p
        onClick={() => openInNewTab("https://waseem.works")}
        style={{ color: textColor }}
        >
          waseem.works
        </p>
        <p
          onClick={() => openInNewTab("https://github.com/devwaseem/TempBox")}
          style={{ color: textColor }}
        >
          Github
        </p>
        <p
          onClick={() => openInNewTab("https://twitter.com/TempboxMac")}
          style={{ color: textColor }}
        >
          Twitter
        </p>
        <div 
          className="download-button"
          style={{ color: buttonTextColor, backgroundColor: buttonBackground }}
          onClick={() => openInNewTab(downloadUrl)}
        >
          Download for Mac
        </div>
      </div>
    </div>
  </footer>
  )
};

export default Footer;