import React from 'react'
import Helmet from 'react-helmet'
import logo from '../images/tempbox_logo.png'

function SEO() {

  const keywords = ["Tempbox", "free", "temporary", "email", "disposable", "mail", "email address", "free temp mail", "free temp mail for mac", "disposable email for mac", "temp mail for mac", "temp mail for apple" ,"disposable email", "10 min mail", "temp mail", "temp mail gmail", "instant disposable email", "disposable email for mac", "temp mail for facebook", "tempmail for gmail", "temp mail login", "temp mail generator", "gmail temp mail", "10 minutes temp mail", "temp mail address", "temp mail org", "best temp mail", "mail.tm", "free temp mail", "temp mail io", "temp mail id", "best temp mail for facebook", "yahoo temp mail"]
  const meta = []

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={"Tempbox: Instant disposable emails client for Mac"}
      meta={[
        {
          name: `description`,
          content: 'Instant free disposable emails client for Mac',
        },
        {
          property: `og:title`,
          content: 'TempBox',
        },
        {
          property: `og:description`,
          content: 'Instant disposable emails client for Mac',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: logo,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'iamwaseem99',
        },
        {
          name: `twitter:title`,
          content: 'Tempbox',
        },
        {
          name: `twitter:description`,
          content: 'Instant disposable emails client for Mac',
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

export default SEO;
